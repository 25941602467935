/* src/App.css */
body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f4f4f4;
  padding-bottom: 60px; /* Space for the fixed footer */
}

header {
  background: url('../src/images/backgroundimage.jpg') no-repeat center center/cover;
  color: white;
  padding: 2rem;
  text-align: center;
}

nav {
  display: flex;
  justify-content: center;
  background-color: #333;
}

nav a {
  color: white;
  padding: 1rem;
  text-decoration: none;
  text-align: center;
}

nav a:hover {
  background-color: #575757;
}

section {
  padding: 2rem;
}

.gallery img {
  width: 100%;
  max-width: 300px;
  margin: 1rem;
}

footer {
  text-align: center;
  padding: 1rem;
  background-color: #333;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
  height: 60px; /* Match this with the padding-bottom of body */
}

.btn {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.btn:hover {
  background-color: #45a049;
}

.social-media {
  text-align: center;
  margin-top: 1rem;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.5rem;
}

.social-links a {
  color: #4CAF50;
  text-decoration: none;
}

.social-links a:hover {
  text-decoration: underline;
}


/* src/components/Gallery.css */
.gallery {
  padding: 2rem;
  text-align: center;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40vh; /* Adjust the height as needed */
  width: 100%;
  margin-bottom: 1rem; /* Reduce the space between the image and dots */
}

.image-container img {
  height: 100%;
  /* width: auto; */
  max-width: 50%;
  object-fit: fill;
  
}

.dots-container {
  display: flex;
  justify-content: center;
  margin-top: 0.5rem; /* Adjust the space between the image and dots */
}

.dot {
  height: 15px;
  width: 15px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.dot.active {
  background-color: #717171;
}
